import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Typography, FormControlLabel, Switch, Tabs, Tab, FormGroup, Tooltip, Chip, CircularProgress } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhoneIcon from '@mui/icons-material/Phone';
import { useNavigate, useLocation } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QRCode from "react-qr-code";
import { getPartners, getCompanyDetails } from '../../Utils/apiUtils';
import EmployeesList from './EmployeesList';
import './CompanyOverview.css';




type CompanyDetails = {
    [key: string]: string | number | boolean | object,
    company: string,
    status: string,
    contactDetails: Array<{ name: string, email: string, contactnumber: string }>,
    domains: Array<string>,
    address1: string,
    address2: string,
    city: string,
    state: string,
    zipcode: string,
    companySize: string,
    journeys: any,
    companyUrl: string,
    companyCode: string,
    integrations: Array<string>,
    partnerRanking: any,
    userCreationByCompanyCode: boolean,
    userCreationByEmailDomain: boolean
}
const initialCompanyDetails = {
    company: "",
    status: "Active",
    contactDetails: [{ name: "", email: "", contactnumber: "" }],
    domains: [""],
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    companySize: "",
    journeys: {
        caregiving: {
            enabled: false,
            partners: {
                partner: [],
                configuration: []
            }
        },
        parenting: {
            enabled: false,
            partners: {
                partner: [],
                configuration: []
            }
        }, wellness: {
            enabled: false,
            partners: {
                partner: [],
                configuration: []
            }
        }
    },
    companyUrl: "",
    companyCode: "",
    integrations: [],
    partnerRanking: {},
    userCreationByCompanyCode: false,
    userCreationByEmailDomain: false
}


type configDetails = { partnername: string, partnerconfig: Array<string> };

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CompanyOverview = () => {
    const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(initialCompanyDetails);
    const [partners, setPartners] = useState<any>({});
    const navigate = useNavigate();
    const [tabId, setTabId] = React.useState(0);
    const [openURLTooltip, setURLTooltip] = React.useState(false);
    const [openCodeTooltip, setCodeTooltip] = React.useState(false);
    const [urlImageTooltip, setUrlImageTooltip] = React.useState(false);
    const [isCompanyLoading, setCompanyLoading] = useState(true);
    const [isPartnersLoading, setParnetersLoading] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const location: any = useLocation();

    useEffect(() => {
        getPartners().then((res: any) => {
            setPartners(res)
            setParnetersLoading(false)
        })
    }, [])
    useEffect(() => {
        getCompanyDetails(location.state.id).then((res: any) => {
            setCompanyDetails(res)
            setCompanyLoading(false)
        })
    }, [location.state.id]);

    useEffect(() => {
        if (!isCompanyLoading && !isPartnersLoading) {
            setLoading(false)
        }
    }, [isCompanyLoading, isPartnersLoading]);



    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabId(newValue);
    };

    const handleURLCopy = () => {
        copyToClipboard(companyDetails.companyUrl).then(() => {
            setURLTooltip(true)
            setTimeout(() => setURLTooltip(false), 2000)
        })
    }
    const handleCodeCopy = () => {
        copyToClipboard(companyDetails.companyCode).then(() => {
            setCodeTooltip(true)
            setTimeout(() => setCodeTooltip(false), 2000)
        })
    }

    const handleQRCodeCopy = () => {
        handleQRCodeCopyClipboard()
        setUrlImageTooltip(true)
        setTimeout(() => setUrlImageTooltip(false), 2000)

    }

    const downloadQRCode = () => {
        const svg = document.getElementById("QRCode");
        const svgData = new XMLSerializer().serializeToString(svg!);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx!.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.download = "QRCode";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;

    }

    const handleQRCodeCopyClipboard = () => {
        const svg = document.getElementById("QRCode");
        const svgData = new XMLSerializer().serializeToString(svg!);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx!.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            fetch(pngFile)
                .then(res => res.blob())
                .then(blob => {
                    const data = [new ClipboardItem({ ['image/png']: blob! })];
                    navigator.clipboard.write(data);
                })
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    }

    function copyToClipboard(textToCopy: string) {
        let navigator: any = window.navigator
        if (navigator.clipboard && window.isSecureContext) {
            return navigator.clipboard.writeText(textToCopy);
        } else {
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res: any, rej: any) => {
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    }

    return (
        <>
            <Button variant="text" sx={{ mt: 1 }} startIcon={<ArrowBackIcon />} onClick={() => navigate("/companies")}>Back to list</Button>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1,
                        width: '45%',
                    },
                }}
            >
                <Paper elevation={2} sx={{ padding: '24px 32px' }}>
                    {isCompanyLoading ? <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></Box>
                        :
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: "16px" }}>
                                <div style={{ width: '80%' }}>
                                    <Typography color="text.secondary" variant="h3">
                                        {companyDetails.company}
                                    </Typography>
                                </div>
                                <div style={{ width: '20%' }}>
                                    {companyDetails.status === "Active" ? <Chip icon={<DoneIcon className="doneChip-icon" />} label={companyDetails.status} className="MuiActive-chip" variant="outlined" size="small" />
                                        : <Chip icon={<CancelIcon />} label={companyDetails.status} className="MuiInactive-chip" variant="outlined" size="small" />}
                                </div>
                            </div>
                            <br />
                            {companyDetails.contactDetails && companyDetails.contactDetails.map(contact => {
                                return (
                                    <>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                                            Contact : {contact.name}
                                        </Typography>

                                        <Typography sx={{ display: 'flex' }} variant="h6">
                                            <div style={{ display: 'inherit', width: '50%' }}><EmailIcon color="primary" fontSize="small" />&nbsp; {contact.email}</div>
                                            <div style={{ display: 'inherit', width: '50%' }}><PhoneIcon color="primary" fontSize="small" />&nbsp; {contact.contactnumber}</div>
                                        </Typography>
                                        <br />
                                    </>
                                )
                            })}
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                                        Address
                                    </Typography>
                                    <Typography variant="h6">
                                        {companyDetails.address1}
                                    </Typography>
                                    <Typography variant="h6">
                                        {companyDetails.address2}
                                    </Typography>
                                    <Typography variant="h6">
                                        {(companyDetails.city ? companyDetails.city + ', ' : '') + (companyDetails.state ? companyDetails.state + " " : '') + (companyDetails.zipcode ? companyDetails.zipcode : '')}
                                    </Typography>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                                        Company Size
                                    </Typography>
                                    <Typography variant="h6">
                                        {companyDetails.companySize}
                                    </Typography>
                                </div>
                            </div>
                            <br />
                            {
                                companyDetails.userCreationByEmailDomain && (
                                    <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                                        Company Email Domain(s)
                                    </Typography>
                                )
                            }
                            {companyDetails.userCreationByEmailDomain && companyDetails.domains && companyDetails.domains.map(domain => {
                                return (
                                    <Typography variant="h6">
                                        {domain}
                                    </Typography>
                                )
                            })}
                            <br />
                            <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                                Account Creation
                            </Typography>
                            <Typography variant="h6">
                                {
                                    companyDetails.userCreationByCompanyCode && "Company Code"
                                }
                                {
                                    companyDetails.userCreationByEmailDomain && "Email domain"
                                }
                            </Typography>
                            <br />
                            <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                                Integrations
                            </Typography>
                            {companyDetails.integrations && companyDetails.integrations.map(product => {
                                return (
                                    <Typography variant="h6">
                                        {product}
                                    </Typography>
                                )
                            })}
                            <br />
                            <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                                Unum Care Hub Unique Company URL
                            </Typography>
                            <Typography sx={{ fontSize: '16px' }}>
                                {companyDetails.companyUrl}
                                {companyDetails.companyUrl && <Tooltip
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    placement="top"
                                    arrow
                                    open={openURLTooltip}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title="Copied to clipboard"
                                >
                                    <Button variant="text" size="small" startIcon={<ContentCopyIcon />} onClick={handleURLCopy}>Copy</Button>
                                </Tooltip>}
                            </Typography>
                            <br />
                            <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                                Company Code
                            </Typography>
                            <Typography variant="h6">
                                {companyDetails.companyCode}
                                <Tooltip
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    placement="top"
                                    arrow
                                    open={openCodeTooltip}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title="Copied to clipboard"
                                >
                                    <Button variant="text" size="small" startIcon={<ContentCopyIcon />} onClick={handleCodeCopy}>Copy</Button>
                                </Tooltip>
                            </Typography>
                            <br />
                            <div className='edit-company'>
                                <Button sx={{ float: 'right' }} variant="text" size="small" startIcon={<EditIcon />} onClick={() => navigate('/Onboarding', { state: { type: "edit", data: companyDetails } })}>Edit</Button>
                            </div>
                        </>
                    }
                </Paper>
                <div style={{ width: '45%' }}>
                    <Paper elevation={2} sx={{ padding: '24px 32px' }}>
                        {isPartnersLoading ? <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></Box>
                            :
                            <>
                                <Typography variant="h5">
                                    Journey Settings
                                </Typography>
                                <Typography sx={{ fontSize: '14px', m: '8px 0 24px 0' }}>
                                    Edit company details to update journey and partner settings
                                </Typography>
                                <FormControlLabel sx={{ fontSize: '12px', mb: '16px' }} control={<Switch sx={{ fontSize: '12px' }} checked={companyDetails.journeys["wellness"].enabled} name="wellness" />} label="Wellness (no partners)" />
                                <FormControlLabel sx={{ fontSize: '12px', mb: '16px' }} control={<Switch sx={{ fontSize: '12px' }} checked={companyDetails.journeys["caregiving"].enabled} name="caregiving" />} label="Caregiving" />
                                <FormControlLabel sx={{ fontSize: '12px', mb: '16px' }} control={<Switch checked={companyDetails.journeys["parenting"].enabled} name="parenting" />} label="Parenting" />
                                {!companyDetails.journeys["wellness"].enabled &&
                                    <>
                                        <Typography variant="h5">
                                            Partner Settings
                                        </Typography>
                                        <Box className="MuiBox" sx={{ borderBottom: 1, borderColor: '#0199A6', width: '99%' }} >
                                            <Tabs value={tabId} onChange={handleChange} aria-label="basic tabs example">
                                                <Tab className="Mui-tabs" label="Caregiving" {...a11yProps(0)} />
                                                <Tab className="Mui-tabs" label="Parenting" {...a11yProps(1)} />
                                            </Tabs>
                                        </Box>
                                        <TabPanel value={tabId} index={0}>
                                            <FormGroup row={false}>
                                                {partners["caregiving"] && partners["caregiving"].map((partner: string) => {

                                                    const config = companyDetails.journeys["caregiving"].partners.configuration?.find(((config: configDetails) => config.partnername === partner)) || {};

                                                    return (<div className="partner-switch" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <FormControlLabel key={partner} sx={{ fontSize: '14px' }} style={{ width: "50%" }} control={<Switch checked={companyDetails.journeys["caregiving"].partners.partner.includes(partner)} name={partner} />} label={partner} />
                                                        {Object.keys(companyDetails.partnerRanking).includes(partner) && <Typography sx={{ fontSize: '14px' }}><span style={{ fontWeight: "600" }}>Position:</span> {companyDetails.partnerRanking[partner]}</Typography>}
                                                        &nbsp;&nbsp;&nbsp;
                                                        {companyDetails.journeys["caregiving"].partners.partner.includes(partner) && <Typography sx={{ fontSize: '14px' }} ><span style={{ fontWeight: "600" }}>Config:</span>  {config.partnerconfig ? config.partnerconfig.includes("EmailDomain") && "Domain" : ""}{config.partnerconfig ? config.partnerconfig.length > 1 && ", " : ""}{config.partnerconfig ? config.partnerconfig.includes("CompanyCode") && "Code" : ""}{config.partnerconfig ? config.partnerconfig.length > 1 && ", " : ""}{config.partnerconfig ? config.partnerconfig.includes("Urbansitter") && "Urbansitter" : ""}</Typography>}

                                                    </div>)
                                                })}
                                            </FormGroup>
                                        </TabPanel>
                                        <TabPanel value={tabId} index={1}>
                                            <FormGroup row={false}>
                                                {partners["parenting"] && partners["parenting"].map((partner: string) => {
                                                    const config = companyDetails.journeys["parenting"].partners.configuration?.find(((config: configDetails) => config.partnername === partner)) || {};
                                                    return (<div className="partner-switch" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        < FormControlLabel key={partner} sx={{ fontSize: '14px' }} style={{ width: "50%" }} control={< Switch checked={companyDetails.journeys["parenting"].partners.partner.includes(partner)} name={partner} />} label={partner} />
                                                        {Object.keys(companyDetails.partnerRanking).includes(partner) && <Typography sx={{ fontSize: '14px' }}><span style={{ fontWeight: "600" }}>Position:</span> {companyDetails.partnerRanking[partner]}</Typography>}
                                                        &nbsp;&nbsp;&nbsp;
                                                        {companyDetails.journeys["parenting"].partners.partner.includes(partner) && <Typography sx={{ fontSize: '14px' }}><span style={{ fontWeight: "600" }}>Config:</span> {config.partnerconfig ? config.partnerconfig.includes("EmailDomain") && "Domain" : ""}{config.partnerconfig ? config.partnerconfig.length > 1 && ", " : ""}{config.partnerconfig ? config.partnerconfig.includes("CompanyCode") && "Code" : ""}</Typography>}
                                                    </div>)
                                                })}
                                            </FormGroup>
                                        </TabPanel>
                                    </>
                                }
                                {/* <Typography variant="h5" sx={{ m: '24px 0px 5px 0px' }}>
                                    Employer Resources
                                </Typography>
                                <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
                                    Add, update or edit resources provided to employees
                                </Typography>
                                <Button variant="contained" sx={{ mt: '10px' }} size="small" onClick={() => navigate('/ResourceEditor', { state: { ...companyDetails, id: location.state.id } })}>View Employer Resources Editor</Button> */}
                            </>
                        }
                    </Paper>
                    <br />
                    <br />
                    <Paper elevation={2} >
                        <div className='boxi'>
                            <div style={{ padding: '24px 0px 24px 20px', width: '77%' }}>
                                <Typography sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '26px', m: 1 }}>
                                    Company QR Code
                                </Typography>
                                <Typography sx={{ font: 'tp/Font', fontSize: '16px', fontWeight: 400, lineHeight: '24px', m: 1 }}>
                                    <a style={{ color: '#037CB7', cursor: 'pointer' }} onClick={downloadQRCode}>Download</a>  the attached QR code to optimize the account creation process for everyone in your team.
                                </Typography>
                            </div>
                            <div className='boxi-inside' style={{ width: '23%' }}>
                                <div className='boxi-inside-inside'>
                                    <Tooltip
                                        PopperProps={{ disablePortal: true }}
                                        placement="top"
                                        arrow
                                        open={urlImageTooltip}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title="Copied to clipboard"
                                        sx={{ justifyContent: 'flex-end' }}
                                        id="nmone"
                                    >
                                        <Button variant="text" size="small" startIcon={<ContentCopyIcon />} onClick={handleQRCodeCopy}>Copy</Button>
                                    </Tooltip>
                                </div>
                                <div className='qr-code-company-url'>
                                    <QRCode
                                        id="QRCode"
                                        style={{ height: "76px", width: "76px" }}
                                        value={companyDetails.companyUrl}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                            </div>
                        </div>
                    </Paper>
                </div >
            </Box>
            <EmployeesList />

        </>
    );
}
export default CompanyOverview;
