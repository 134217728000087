import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import { Typography, CircularProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { StyledTableCell, StyledTableRow } from "./CompanyList";
import CustomAlert from "../Elements/CustomAlert";
import CustomDialog from "../Elements/CustomDialog";
import { useNavigate, useLocation } from "react-router-dom";
import { getEmployeeList, deleteEmployee, getEmployeeListFilter } from "../../Utils/apiUtils";
import SearchBar from "../Elements/SearchBar/Searchbar";
import "./EmployeeList.css";
import { escapeRegex } from "../../Utils/helperUtils";
import NoResults from "../../assets/no_results.png";

type employeeDetails = {
  id: string;
  firstname: string;
  lastname: string;
  email: string | undefined;
  contactNumber: string;
  state: string;
  companyId: string;
  companyName: string;
  companyCode: string;
  journeys: string;
  status: string;
  personalEmailCode: string | undefined;
};

type CustomConfirmProps = {
  isOpen: boolean;
  title: string;
  body: string;
  onConfirm?: (event: Event | React.SyntheticEvent<any, Event>) => void;
};

type PageResult = {
  items: Array<employeeDetails>;
  lastEvaluatedKey: string;
  isForward: boolean;
  limitQuery: number;
  itemsCount: number;
};

interface EmployeeListRequest {
  companyId: string;
  limitRequest: number;
  filter: string[];
  isForward: boolean;
  lastEvaluatedKey: string | null;
}

const EmployeesList = () => {
  const [employeeList, setEmployeeList] = useState<Array<employeeDetails>>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isEmployeesLoading, setEmployeesLoading] = useState(false);
  const [alert, setAlert] = useState<any>({});
  const [confirmDialog, setConfirmDialog] = useState<CustomConfirmProps>({
    isOpen: false,
    title: "",
    body: "",
  });
  const navigate = useNavigate();
  const location: any = useLocation();
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | null>(null);
  const [disabledNextArrow, setDisabledNextArrow] = useState<boolean>(false);
  const [disabledPreviusArrow, setDisabledPreviusArrow] = useState<boolean>(true);
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    setEmployeesLoading(true);
    const data: EmployeeListRequest = {
      companyId: location.state.id,
      limitRequest: rowsPerPage,
      filter: tags,
      isForward: true,
      lastEvaluatedKey: null
    };

    handleGetEmployeeListFilter(data);
  }, [location.state.id, tags, rowsPerPage]);

  const handleGetEmployeeListFilter = (requestData: EmployeeListRequest) => {
    getEmployeeListFilter(requestData).then((res: PageResult) => {
      setLastEvaluatedKey(res.lastEvaluatedKey);

      setDisabledNextArrow(false);


      console.log("page", page)
      if (!res.lastEvaluatedKey && page !== 1 && page !== 0) {
        setDisabledNextArrow(true);
      }

      if (res.itemsCount === 0 || res.itemsCount <= rowsPerPage) {
        setDisabledNextArrow(true);
        setDisabledPreviusArrow(true);
      }

      setEmployeeList(res.isForward ? res.items : res.items.reverse());
      setCount(res.itemsCount)
      setEmployeesLoading(false);
    });
  };

  const handleSearch = (searchTags: string[]) => {
    setTags(searchTags);
    setPage(0)
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    let isForward = true;
    setPage((prevPage) => {
      isForward = prevPage < newPage;
      return newPage;
    });

    if (newPage === 0) {
      setDisabledPreviusArrow(true);
    } else {
      setDisabledPreviusArrow(false);
    }

    setEmployeesLoading(true);
    const data: EmployeeListRequest = {
      companyId: location.state.id,
      limitRequest: rowsPerPage,
      filter: tags,
      isForward,
      lastEvaluatedKey: isForward ? btoa(JSON.stringify({ "pk": { "BS": [], "L": [], "M": {}, "NS": [], "S": location.state.id, "SS": [] }, "sk": { "BS": [], "L": [], "M": {}, "NS": [], "S": employeeList.reverse()[0].id, "SS": [] } })) : btoa(JSON.stringify({ "pk": { "BS": [], "L": [], "M": {}, "NS": [], "S": location.state.id, "SS": [] }, "sk": { "BS": [], "L": [], "M": {}, "NS": [], "S": employeeList[0].id, "SS": [] } }))
    };

    handleGetEmployeeListFilter(data);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const highlightText = (text: string) => {
    if (tags.length === 0) {
      return text;
    }

    const escapedTags = tags.map(escapeRegex);
    const parts = text.split(new RegExp(`(${escapedTags.join("|")})`, "gi"));
    return parts.map((part, index) =>
      escapedTags.some((tag) => new RegExp(tag, "i").test(part)) ? <b key={index}>{part}</b> : part
    );
  };

  const handleDelete = (company: string, id: string) => {
    const request = {
      companyId: company,
      employeeId: id,
    };
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    setEmployeesLoading(true);
    deleteEmployee(request).then((res) => {
      setAlert({ type: res.type, message: res.message, open: true });
      setEmployeesLoading(true);
      getEmployeeList(location.state.id).then((res: any) => {
        setEmployeeList(res);
        setEmployeesLoading(false);
      });
      setEmployeesLoading(false);
    });
  };
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({});
  };

  const renderTable = () => {
    if (employeeList.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={5}>
            <Grid
              sx={{ pt: "120px", pb: "90px" }}
              container
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <img src={NoResults} alt="no_results" width="125px" />
              <Typography
                variant="h3"
                sx={{
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "32px",
                  pt: "24px",
                  color: "#025780 !important",
                }}
              >{`Sorry! No results found for "${tags.join(", ")}"`}</Typography>
              <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px" }}>
                You may try looking for another company name.
              </Typography>
            </Grid>
          </TableCell>
        </TableRow>
      );
    }

    return employeeList.map((row: employeeDetails, index: number) => (
      <StyledTableRow key={row.id}>
        <StyledTableCell component="th" scope="row">
          {highlightText(row.firstname)}
        </StyledTableCell>
        <StyledTableCell>{highlightText(row.lastname)}</StyledTableCell>
        <StyledTableCell>{highlightText(row.email || row.personalEmailCode || "")}</StyledTableCell>
        <StyledTableCell>{row.journeys.replace(/[,]/g, ", ")}</StyledTableCell>
        <StyledTableCell sx={{ width: "18%", padding: 0 }}>
          <Button
            size="small"
            sx={{ width: "40px", minWidth: "0" }}
            startIcon={<EditIcon />}
            onClick={() => navigate("/EditEmployee", { state: { type: "edit", companyId: row.companyId, id: row.id } })}
          ></Button>
          <Button
            sx={{ color: "#B30303", width: "40px", minWidth: "0" }}
            color="error"
            aria-controls="ringtone-menu"
            variant="text"
            size="small"
            startIcon={<DeleteIcon />}
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure?",
                body: "This action cannot be undone. Are you sure you want to delete this employee?",
                onConfirm: () => {
                  handleDelete(row.companyId, row.id);
                },
              });
            }}
          ></Button>
        </StyledTableCell>
      </StyledTableRow>
    ));
  };

  return (
    <Paper elevation={2} sx={{ m: 1, mr: 1, width: "91.7%", padding: "24px 32px" }}>
      {alert.open && (
        <CustomAlert open={alert.open} message={alert.message} severity={alert.type} handleClose={handleClose} />
      )}
      <div className="employeelist-header">
        <div>
          <Typography variant="h5">Employees</Typography>
          <Typography sx={{ fontSize: "14px", mb: 2 }}>Employees currently enrolled in the platform</Typography>
        </div>
        <div>
          <Button
            sx={{ float: "right" }}
            variant="text"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => navigate("/EditEmployee", { state: { type: "add", companyId: location.state.id } })}
          >
            Add New Employee
          </Button>
        </div>
      </div>
      <Grid sx={{ pb: "23px" }}>
        <SearchBar onSearch={handleSearch} />
      </Grid>
      {isEmployeesLoading ? (
        <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>First Name</StyledTableCell>
                  <StyledTableCell>Last Name</StyledTableCell>
                  <StyledTableCell>Username</StyledTableCell>
                  <StyledTableCell>Journeys</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderTable()}</TableBody>
            </Table>
          </TableContainer>
          <CustomDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}></CustomDialog>
          <TablePagination
            component="div"
            className="table-pagination"
            rowsPerPageOptions={[5, 10, 15]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            slotProps={{
              actions: {
                nextButton: {
                  disabled: disabledNextArrow,
                  color: "primary",
                },
                previousButton: {
                  disabled: disabledPreviusArrow,
                  color: "primary",
                },
              },
            }}
          />
        </>
      )}
    </Paper>
  );
};
export default EmployeesList;
